import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { media } from '@dfds-ui/theme'
import { gridMap, isContentfulType } from '../utils'
import { LinkboxCarousel } from '../components/LinkboxCarousel'
import { TextAndMediaLayout } from '../components/TextAndMedia'
import { MapLayout } from '../components/Map'
import { NewsletterSubscribeBlock } from '../components/Newsletter'
import { Trustpilot } from '../components/Trustpilot'
import { PriceDisclaimer } from '../components/PriceDisclaimer'
import { PageLayout } from '../components/Layout'
import { TourOperatorWidget } from '../components/Widgets'
import { Faq } from '../components/FAQ'
import { PaxCovidBanner } from '../components/PaxCovidBanner'
import Hero from '../components/Hero/Hero'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      &[md="6"], &[lg="6"] {
        width: calc(50% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
      &[md="3"], &[lg="3"] {
        width: calc(25% - 30px);
      }
    `}
  }
`

const TravelGuidePage = ({ data: { content } }) => {
  const {
    hero,
    covidBannerLayout,
    miniBookReference,
    tourOperatorWidget,
    textAndMediaContentReference,
    mapCoordinates,
    mapContentReference,
    optionalTextAndMediaContentReferencesCollection,
    linkboxSlider1Title,
    linkboxSlider1Reference,
    linkboxSlider2Title,
    linkboxSlider2Reference,
    optionalNewsletterSubscribeBlock,
    trustpilotRef,
    additionalTrustpilotRef,
    faqSection,
    sys,
  } = content.page

  return (
    <PageLayout>
      {hero?.contentRef && (
        <>
          <Hero {...hero.contentRef} miniBookReference={miniBookReference} />
          {covidBannerLayout?.localisedContentReference?.header &&
            typeof sessionStorage !== 'undefined' &&
            sessionStorage.covidPaxBannerNotVisible !== 'true' && (
              <PaxCovidBanner
                {...covidBannerLayout?.localisedContentReference}
              />
            )}
        </>
      )}
      {tourOperatorWidget && <TourOperatorWidget {...tourOperatorWidget} />}
      <TextAndMediaLayout
        reverse
        mediaWidth={{ md: 8 }}
        makeTitleAnH1
        title={textAndMediaContentReference.title}
        text={textAndMediaContentReference.text}
        images={textAndMediaContentReference.imagesCollection.items}
        links={textAndMediaContentReference.linksCollection.items}
        {...textAndMediaContentReference}
      />
      {trustpilotRef && (
        <Trustpilot
          {...trustpilotRef}
          template="travelGuidePage"
          id={sys.id}
          item="trustpilotRef"
        />
      )}
      {mapContentReference && (
        <MapLayout
          sys={mapContentReference.sys}
          location={mapCoordinates}
          title={mapContentReference.title}
          text={mapContentReference.text}
          cardProps={{ mediaWidth: { md: 8 } }}
          mapAlignment={'Left'}
        />
      )}
      {optionalTextAndMediaContentReferencesCollection && (
        <Wrapper>
          {optionalTextAndMediaContentReferencesCollection.items.map(
            (item, index) => {
              const unevenContextblockNumber =
                optionalTextAndMediaContentReferencesCollection.items.filter(
                  (item) => item.__typename !== 'contentful_TextAndMediaLayout',
                ).length %
                  2 >
                  0 && index === 0

              if (
                isContentfulType(
                  item.__typename,
                  'contentful_TextAndMediaLayout',
                )
              ) {
                return (
                  <TextAndMediaLayout
                    key={item.sys.id}
                    blockWidth={item.blockWidth}
                    mediaWidth={gridMap[item.mediaWidth]}
                    {...item}
                  />
                )
              } else {
                return (
                  <TextAndMediaLayout
                    key={item.sys.id}
                    blockWidth={
                      unevenContextblockNumber ? 'full-width' : 'one-half'
                    }
                    mediaWidth={
                      unevenContextblockNumber ? 'two-thirds' : 'full-width'
                    }
                    mediaPosition={
                      unevenContextblockNumber ? 'right' : 'bottom'
                    }
                    text={item.text}
                    images={item.imagesCollection.items}
                    links={item.linksCollection.items}
                    {...item}
                  />
                )
              }
            },
          )}
        </Wrapper>
      )}
      {additionalTrustpilotRef && (
        <Trustpilot
          {...additionalTrustpilotRef}
          template="travelGuidePage"
          id={sys.id}
          item="additionalTrustpilotRef"
        />
      )}
      <LinkboxCarousel
        {...linkboxSlider1Reference}
        title={linkboxSlider1Title || linkboxSlider1Reference.title}
      />
      <LinkboxCarousel
        {...linkboxSlider2Reference}
        title={linkboxSlider2Title || linkboxSlider2Reference.title}
      />
      {optionalNewsletterSubscribeBlock && (
        <NewsletterSubscribeBlock {...optionalNewsletterSubscribeBlock} />
      )}
      {faqSection?.itemsCollection?.items &&
        faqSection?.itemsCollection?.items.length !== 0 && (
          <Faq
            {...faqSection.itemsCollection}
            heading={faqSection?.heading}
            urlSlug={faqSection?.urlSlug?.slug}
            defaultExpanded={faqSection?.defaultExpanded ?? false}
          />
        )}
      <PriceDisclaimer />
    </PageLayout>
  )
}

export const query = graphql`
  query TravelGuidePage(
    $id: String!
    $locale: String!
    $preview: Boolean = false
  ) {
    content: contentful {
      page: travelGuidePage(id: $id, locale: $locale, preview: $preview) {
        sys {
          id
        }
        hero: heroReference {
          ... on contentful_PaxHeroLayout {
            contentRef {
              ...PaxHeroContent
            }
          }
        }
        covidBannerLayout {
          localisedContentReference {
            ...CovidBanner
          }
        }
        miniBookReference {
          ...Minibook
          ...MinibookProductList
        }
        tourOperatorWidget {
          ...TourOperatorWidget
        }
        textAndMediaContentReference {
          ...TextAndMediaContent
        }
        mapCoordinates {
          lat
          lon
        }
        mapContentReference {
          sys {
            id
          }
          title
          text
        }
        optionalTextAndMediaContentReferencesCollection(limit: 10) {
          items {
            ...TextAndMediaLayout
            ...TextAndMediaContent
          }
        }
        linkboxSlider1Title
        linkboxSlider1Reference {
          ...LinkboxCarousel
        }
        linkboxSlider2Title
        linkboxSlider2Reference {
          ...LinkboxCarousel
        }
        trustpilotRef {
          ...Trustpilot
        }
        additionalTrustpilotRef {
          ...Trustpilot
        }
        optionalNewsletterSubscribeBlock {
          ...NewsletterSubscribeBlock
        }
        faqSection {
          ...FaqFragment
        }
      }
    }
  }
`

export default TravelGuidePage
